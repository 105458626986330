<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>{{ pageTitle }}</h4>
      <div>
        <b-button
          v-if="componentMode === 'create'"
          type="submit"
          form="placeTypeForm"
          class="mr-2"
          variant="outline-primary"
        >
          Создать
        </b-button>
        <b-button
          v-if="componentMode === 'edit'"
          type="submit"
          form="placeTypeForm"
          class="mr-2"
          variant="outline-primary"
        >
          Сохранить
        </b-button>
        <b-button variant="outline-secondary" @click="cancel">Отмена</b-button>
      </div>
    </div>
    <form @submit.prevent="onSubmit" id="placeTypeForm">
      <b-row>
        <b-col cols="6">
          <b-form-group label="Название" label-class="pb-0">
            <b-form-input v-model="title" required autofocus />
          </b-form-group>
          <b-form-group label="Название во множественном числе" label-class="pb-0">
            <b-form-input v-model="title_plural" required />
          </b-form-group>
          <b-form-group label="Тип" label-class="pb-0">
            <b-form-select v-model="type" :options="typeOptions"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import { PlaceTypes } from '@/request';

export default {
  name: 'PlaceType',
  data() {
    return {
      title: '',
      title_plural: '',
      type: 'travel',
    };
  },
  computed: {
    componentMode() {
      return this.$route?.meta?.mode || 'create';
    },
    pageTitle() {
      return this.componentMode === 'edit' ? 'Редактировать тип места' : 'Создать тип места';
    },
    typeOptions() {
      return [
        { value: 'travel', text: 'Посетить' },
        { value: 'rest', text: 'Отдых' },
      ];
    },
  },
  mounted() {
    if (this.componentMode === 'edit') {
      this.getPlaceType();
    }
  },
  methods: {
    async getPlaceType() {
      try {
        Object.assign(this.$data, await PlaceTypes.getPlaceType(this.$route.params.id));
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    onSubmit() {
      switch (this.componentMode) {
        case 'create':
          this.createPlaceType();
          break;
        case 'edit': {
          this.updatePlaceType();
          break;
        }
        default:
          return;
      }
    },
    async createPlaceType() {
      try {
        await PlaceTypes.createPlaceType(this.$data);
        this.$bvToast.toast('Новый тип места создан', { variant: 'success' });
        await this.$router.push({ name: 'PlaceTypes' });
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    async updatePlaceType() {
      try {
        await PlaceTypes.updatePlaceType(this.$route.params.id, this.$data);
        await this.$router.push({ name: 'PlaceTypes' });
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    cancel() {
      this.$router.push({ name: 'PlaceTypes' });
    },
  },
};
</script>

<style lang="scss" scoped></style>
